import { Option1} from './homeNew.jsx';
import NovaHomeNavBar from './novaHomeNavBar.jsx';
import Footer from '../Footer/index.jsx';

function HomepageDesignToggler() {
  return (<>
    <Option1 />
    <Footer />
  </>);
}

export default HomepageDesignToggler;