import React from "react";
import "./style.css";
import Plans from "./divprecos";


function HomeDivPreco() {
  return (<>
    <Plans />
  </>
  );
}

export default HomeDivPreco;